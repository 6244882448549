import React, { createContext, useContext, useState, useEffect } from "react";
import { getStatusForm, setStatusForm as persistStatusForm } from "../utils/statusForm";

const StatusFormContext = createContext();

export const useStatusForm = () => useContext(StatusFormContext);

export const StatusFormProvider = ({ children }) => {
  const [statusForm, setStatusFormState] = useState(getStatusForm);

  const setStatusForm = (statusForm) => {
    setStatusFormState(statusForm);
    persistStatusForm(statusForm);
  };

  return (
    <StatusFormContext.Provider value={{ statusForm, setStatusForm }}>
      {children}
    </StatusFormContext.Provider>
  );
};
