import React from "react";
import { toast } from "react-toastify";
import axios from "axios";

const FinalizeQuestions = async (
  answers,
  tempRadioAnswers,
  analysis,
  props,
  navigate,
  setLoading,
  questions,
  setOpenError,
  setErrorMessages
) => {
  try {
    const mergedAnswers = { ...answers };

    for (const key in tempRadioAnswers) {
      mergedAnswers[key] = {
        id: tempRadioAnswers[key].id,
        questionAnswer: tempRadioAnswers[key].answer,
        index: tempRadioAnswers[key].id,
      };
    }

    const filledAnswers = Object.keys(mergedAnswers)
      .filter(
        (key) =>
          mergedAnswers[key].questionAnswer !== "" &&
          mergedAnswers[key].id !== null
      )
      .map((key) => Number(key));

    let requiredQuestions = questions.filter(
      (question) =>
        question.clientType === analysis.clientType &&
        question.dependencyQuestionId === null
    );

    const dependentQuestions = questions.filter(
      (question) => question.dependencyQuestionId != null
    );

    // dependentQuestions.forEach((question) => {
    //   const dependentAnswer =
    //     mergedAnswers[question.dependencyQuestionId]?.questionAnswer;
    //   if (
    //     dependentAnswer === "sim" ||
    //     dependentAnswer.includes("Outro") ||
    //     dependentAnswer.includes("PCI de um terceiro")
    //   ) {
    //     requiredQuestions.push(question);
    //   }
    // });

    requiredQuestions = await processDependentQuestions(dependentQuestions, mergedAnswers, requiredQuestions);

    if (analysis.clientType === "PSP") {
      requiredQuestions = requiredQuestions.filter(
        (question) => question.id !== 222 && question.id !== 216
      );
    }

    const requiredQuestionIds = requiredQuestions.map(
      (question) => question.id
    );

    const filteredNonNullAnswers = filledAnswers.filter((answer) =>
      requiredQuestionIds.includes(Number(answer))
    );

    const allIdsPresent = requiredQuestionIds.every((questionId) =>
      filteredNonNullAnswers.some(
        (answer) => Number(answer) === Number(questionId)
      )
    );

    const missingAnswers = requiredQuestionIds.filter(
      (id) => !filteredNonNullAnswers.includes(id)
    );

    // const validationErrors = [];

    // const specificIds = [244, 245];
    // specificIds.forEach((id) => {
    //   const answer = mergedAnswers[id];
    //   const question = questions.find((q) => q.id === id);
    //   if (answer && answer.questionAnswer.length < 8) {
    //     validationErrors.push(`Data inválida: ${question?.question}`);
    //   }
    // });

    const specificIds = [244, 245];
    const validationErrors = await validateAnswers(specificIds, mergedAnswers, questions);

    if (missingAnswers.length > 0) {
      const missingMessages = missingAnswers.map((missingId) => {
        const question = questions.find((q) => q.id === missingId);
        return `Campo vazio: ${question?.question}`;
      });

      validationErrors.push(...missingMessages);
    }

    if (validationErrors.length > 0) {
      setErrorMessages(validationErrors);
      setOpenError(new Array(validationErrors.length).fill(true));

      toast.error(
        "Por favor, responda à todas as perguntas para finalizar o envio do formulário."
      );
      return;
    }

    if (!allIdsPresent) {
      return toast.error(
        "Por favor, responda à todas as perguntas para finalizar o envio do formulário."
      );
    }

    setLoading(true);

    const formattedAnswers = filteredNonNullAnswers.map((key) => ({
      id: mergedAnswers[key].id,
      questionAnswer: mergedAnswers[key].questionAnswer,
      index: mergedAnswers[key].index,
    }));

    await axios.post(
      `${process.env.REACT_APP_API_URL}/form/${analysis?.id}`,
      formattedAnswers,
      {
        headers: {
          Authorization: props.token,
          "Content-Type": "application/json",
        },
      }
    );

    toast.success("Formulário enviado com sucesso.");
    navigate("/homeregistration/");
  } catch (error) {
    toast.error("Erro ao enviar formulário.");
  } finally {
    setLoading(false);
  }
};

const processDependentQuestions = (dependentQuestions, mergedAnswers, requiredQuestions) => {
  return new Promise((resolve, reject) => {
    try {
      dependentQuestions.forEach((question) => {
        const dependentAnswer = mergedAnswers[question.dependencyQuestionId]?.questionAnswer;

        if (dependentAnswer) {
          if (
            dependentAnswer === "sim" ||
            dependentAnswer.includes("Outro") ||
            dependentAnswer.includes("PCI de um terceiro")
          ) {
            requiredQuestions.push(question);
          }
        }
      });
      resolve(requiredQuestions);
    } catch (error) {
      // reject(error);
      resolve(requiredQuestions);
    }
  });
}

const validateAnswers = (specificIds, mergedAnswers, questions) => {
  return new Promise((resolve, reject) => {
    try {
      const validationErrors = [];

      specificIds.forEach((id) => {
        const answer = mergedAnswers[id];
        const question = questions.find((q) => q.id === id);
        if (answer && answer.questionAnswer.length < 8) {
          validationErrors.push(`Data inválida: ${question?.question}`);
        }
      });
      resolve(validationErrors);
    } catch (error) {
      // reject(error);
      resolve([]);
    }
  });
}



export default FinalizeQuestions;
