import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useState, useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import axios from "axios";
import TermosPrivacidade from "./TermosPrivacidade";

const modalStyles = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "white",
  boxShadow: 24,
  p: 4,
  zIndex: 99,
  display: "flex",
  justifyContent: "center",
  textAlign: 'center'
};

const StatusModal = ({
  showModalStatus,
  setShowModalStatus,
  handleConfirmExitWithoutSaving,
  token,
}) => {
  const [analysis, setAnalysis] = useState({});
  const statusValue = analysis.statusRisk;
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(false);
  const getStatus = React.useCallback(async () => {
    try {
      const email = localStorage.getItem("email");
      const response = await axios.get(
        process.env.REACT_APP_API_URL + "/analysisbyemail/" + email,
        {
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
        }
      );
      localStorage.setItem("analysis", JSON.stringify(response.data));

      const id = response.data.id;

      setAnalysis(response.data);
    } catch (error) {
      console.error(error);
    } finally {
    }
  }, [token, navigate]);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (event.target === event.currentTarget) {
        handleCloseModal();
      }
    };

    if (showModalStatus) {
      document.addEventListener("click", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [showModalStatus]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    getStatus();
  }, [getStatus]);

  let message;
  if (statusValue === "EM ANALISE") {
    message = (
      <>
        <p>Por favor, preencha todos os dados requisitados no portal para que o nosso time de risco faça a análise.</p>
        <br />
        Em caso de dúvidas, entre em contato com seu Gerente Comercial.
      </>
    );
  } else if (statusValue === "Aprovado") {
    message = (
      <>
        Proposta aprovada!
        <br />
        <a href="/legalform">Clique</a> para preencher as últimas informações
        para elaboração do nosso contrato.
      </>
    );
  }

  // Estilo do modal baseado no dispositivo móvel
  const modalMobileStyles = isMobile ? { width: "70%" } : {};

  // Função para fechar o modal ao clicar fora dele
  const handleCloseModal = () => {
    setShowModalStatus(false);
  };

  return (
    <>
      {showModalStatus && (
        <Box sx={{ ...modalStyles, ...modalMobileStyles }} id="statusModal">
          <div>
            <p>
              Status do processo: <strong>{statusValue}</strong>
            </p>
            <br />
            <div
              style={{
                flexDirection: "column",
                justifyContent: "center",
              }}
              className="modal-content"
            >
              {message}
            </div>
          </div>
        </Box>
      )}
    </>
  );
};

export default StatusModal;
