import { initializeApp } from 'firebase/app';
import 'firebase/auth';
import { getAnalytics } from 'firebase/analytics';
import {getAuth} from "firebase/auth";

// Configurações do Firebase
const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID
}; 

// Inicialize o Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// Função para renovar o token
async function renewToken() {
  try {
    const user = app.auth().currentUser;
    if (user) {
      // Renove o token
      await user.getIdToken(true);
      // //console.log('Token renovado com sucesso.');
    }
  } catch (error) {
    console.error('Erro ao renovar o token:', error.message);
  }
}  

// Função principal para verificar e renovar o token periodicamente
function checkAndRenewToken() {
  setInterval(async () => {
    const auth = getAuth(app);
    const user = auth.currentUser;

    if (user) {
      // Obtenha a informação do token atual
      const tokenInfo = await user.getIdTokenResult();

      // Defina o tempo de antecedência para renovar o token (por exemplo, 5 minutos antes da expiração)
      const tempoAntecedenciaRenovacao = 5 * 60 * 1000; // 5 minutos em milissegundos

      // Calcule o tempo restante no token
      const tempoRestanteToken = tokenInfo.expirationTime - Date.now();

      // Se estiver perto de expirar, renove o token
      if (tempoRestanteToken < tempoAntecedenciaRenovacao) {
        await renewToken();
      }
    }
  }, 60000); // Verificar a cada 1 minuto (ajuste conforme necessário)
}

// Inicialize a verificação e renovação do token
checkAndRenewToken();
