import * as React from "react";
import { styled } from "@mui/material/styles";
import logo from "../../../src/assets/Logo-Entrepay-Fundo-Vazio.png";
import imgSorvetinho from "../../../src/assets/sorvete rosa.png";
import Button from "@mui/material/Button";
import { useParams } from "react-router-dom";
import { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Modal from "@mui/material/Modal";
import TermosPrivacidade from "../../components/TermosPrivacidade";
import TermosUso from "../../components/TermosUso";

require('../tokenRenewal')

const HeaderLogo = styled("img")({
  width: "380px",
  height: "90px",
});

const logoStyled = {
  alignItems: "center",
  display: "flex",
  justifyContent: "center",
  padding: "40px",
};

function Terms(props) {
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const navigate = useNavigate();

  let { uuid } = useParams();

  const onClickTerms = (event) => {
    if(termoPrivacidade && termoUso){
      setButtonDisabled(true);
      updateTerms();
    }else{
      alert("Você precisa aceitar os Termos de Uso e Política de Privacidade antes de continuar.");
    }
  };

  const updateTerms = async () => {
    try {
      await axios.put(process.env.REACT_APP_API_NO_TOKEN_URL + "/terms/" + uuid, { status: "Aprovado"});
      await axios.post(process.env.REACT_APP_API_NO_TOKEN_URL + "/processanalysis/" + uuid);

      navigate(`/termsComplete/${uuid}`);
    } catch (error) {
      console.error(error);
      setButtonDisabled(false);
    }
  };

  const getTerms = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_API_NO_TOKEN_URL + "/terms/" + uuid,
        {
          headers: {
            Authorization: props.token,
            'Content-Type': 'application/json',
          },
        }
      );
      // //console.log(response);
      setTerms(response.data); // Atualiza o estado com os dados recebidos

    } catch (error) {
      console.error(error);
      navigate("/signin/");
    }
  };

  React.useEffect(() => {
    getTerms(); // Chama a função para obter os termos
  }, []); // Array vazio faz com que o useEffect execute apenas uma vez


  const [terms, setTerms] = useState(false);

  const [termoPrivacidade, setTermoPrivacidade] = useState(false);
  const [termoUso, setTermoUso] = useState(false);

  const [openTermosPrivacidade, setOpenTermosPrivacidade] = useState(false);
  const [openTermosUso, setOpenTermosUso] = useState(false);

  const handleOpenTermosPrivacidade = () => {
    setOpenTermosPrivacidade(true);
  };
  const handleCloseTermosPrivacidade = () => {
    setOpenTermosPrivacidade(false);
  };

  const handleOpenTermosUso = () => {
    setOpenTermosUso(true);
  };

  const handleCloseTermosUso = () => {
    setOpenTermosUso(false);
  };

  
  return (
    <div>
      <div style={logoStyled}></div>
      <div style={{
          maxWidth: "90%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          margin: "auto",
          textAlign: 'center'

        
      }}>
          <div style={{flex:1}}>
              <img src={imgSorvetinho} />
          </div>
          <div style={{
            border: "solid 3px #000",
            borderRadius: 20,
            flex: 1,
            padding: "3rem",
            textAlign: "center"
          }}>
            <div>
                <HeaderLogo className="logo" src={logo} />
            </div>
            <div style={{fontWeight: 600, fontSize: "larger"}}>
                <p>Olá {!!terms && terms.dataCnpj.name}!</p>
                <p>Estamos felizes por você estar aqui.</p>
                <p>Vamos começar nossa jornada?</p>
          </div>
          <div style={{display: "flex", flexDirection: "column", fontWeight: 400, fontSize: "large", lineHeight: 2}}>
              <label>
                  <input
                    type="checkbox"
                    checked={termoPrivacidade}
                    onChange={() => setTermoPrivacidade(!termoPrivacidade)}
                  /> Li e aceito a <a href="#" onClick={handleOpenTermosPrivacidade}>Política de Privacidade </a>
              </label>
              <label>
                  <input
                    type="checkbox"
                    checked={termoUso}
                    onChange={() => setTermoUso(!termoUso)}
                  /> Li e aceito os <a href="#" onClick={handleOpenTermosUso}>Termos de Uso da Plataforma </a>
              </label>
          </div>
          <div style={{marginTop: "50px"}}>
              <Button
                  id="btnConcluir"
                  variant="contained"
                  onClick={onClickTerms}
                  disabled={buttonDisabled}
                  sx={{
                    padding: '16px 16px',
                    lineHeight: 1,
                    backgroundColor: '#e5007e',
                    textTransform: 'none',
                    fontWeight: 'bold',
                    borderRadius: '30px',
                    "&:hover": {
                      color: "white",
                      backgroundColor: "#d4006d",
                    },
                  }}
                >
                    Vamos lá!
                </Button>
          </div>
        </div> 
      </div>

      <Modal open={openTermosPrivacidade} onClose={handleCloseTermosPrivacidade}>
        <TermosPrivacidade handleCloseTermosPrivacidade={handleCloseTermosPrivacidade}/>
      </Modal>
      <Modal open={openTermosUso} onClose={handleCloseTermosUso}>
          <TermosUso handleCloseTermosUso={handleCloseTermosUso}/>
      </Modal>
    </div>
  );
}
export default Terms;
