import * as React from "react";
import { Grid, Typography } from "@mui/material";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";

require('../pages/tokenRenewal')

function TermosUso(props){
    return (
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item>
          <Card
            sx={{
              marginTop: 10,
              marginBottom: 10,
              boxShadow: "0 0 1em #DCDCDC",
              padding: 3,
            }}
          >
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              sx={{ padding: 4 }}
            >
              Termos de Uso da Plataforma
            </Typography>
            <CardContent
              sx={{
                maxWidth: 700,
                overflow: "auto",
                maxHeight: 500,
                display: "flex",
                paddingRight: 4,
                paddingLeft: 6,
                color: "black",
              }}
            >
              <Typography variant="body2" color="text.secondary" style={{height:"45vh"}}>
              <div className="page" title="Page 3">
                  <div className="layoutArea">
                    <div className="column">
                      <h1>TERMOS DE USO</h1>
                      <h3>AVISO</h3>
                      <p>POR FAVOR, LEIA ESSES TERMOS E CONDIÇÕES CUIDADOSAMENTE. AO ACESSAR ESTE SITE E QUAISQUER DE SUAS PÁGINAS, VOCÊ SE COMPROMETE A CUMPRIR OS TERMOS E CONDIÇÕES ABAIXO. SE VOCÊ NÃO CONCORDAR COM OS TERMOS E CONDIÇÕES ABAIXO, NÃO ACESSE ESTE SITE OU QUALQUER DE SUAS PÁGINAS.</p>
                      <p>
                        <strong>COPYRIGHT<span>&nbsp;</span><sup>&copy;</sup><span>&nbsp;</span>C© ENTREPAY - TODOS OS DIREITOS RESERVADOS.</strong>
                      </p>
                      <p><strong>DIREITOS DE PROPRIEDADE INTELECTUAL</strong></p>
                      <p>Os direitos autorais nas páginas e nas telas, nas informações e materiais e em sua organização, é propriedade da EntrePay Serviços de Pagamento S.A e/ou de sociedades pertencentes ao seu grupo econômico, salvo indicação em contrário. As marcas comerciais, logotipos e marcas de serviço exibidas neste site são propriedade da EntrePay, seus afiliados, subsidiárias e outras partes. Nada neste site deve ser interpretado como concessão, por qualquer motivo, de qualquer licença ou direito de uso de qualquer marca comercial, logotipo ou serviço exibido neste site, sem a prévia autorização por escrito da proprietária da marca. Todas as informações e conteúdos, incluindo todos os programas de software disponíveis no site, são protegidos por direitos autorais. Os usuários do site estão proibidos de modificar, copiar, distribuir, transmitir, exibir, publicar, vender, licenciar, criar trabalhos derivados ou utilizar qualquer informação e conteúdo no ou através do site para fins comerciais, públicos ou outros não autorizados.</p>
                      <p><strong>USO DE INFORMAÇOES E MATERIAIS</strong></p>
                      <p>As informações e os materiais contidos nestas páginas e os termos e condições estabelecidos neste documento estão sujeitos a alterações sem aviso prévio. O uso não autorizado de website(s) e sistemas da EntrePay, incluindo, mas não se limitando, a entrada não autorizada nos sistemas da EntrePay, o mal uso de senhas, ou a utilização indevida de qualquer informação publicada no site é estritamente proibido. Nem todos os produtos e serviços estão disponíveis em todas as áreas geográficas de atuação da EntrePay. A elegibilidade para determinados produtos e serviços está sujeita à determinação final pela EntrePay e/ou suas afiliadas.</p>
                      <p><strong>LINKS</strong></p>
                      <p>ESTE SITE PODE CONTER LINKS PARA WEBSITES CONTROLADOS OU OFERECIDOS POR TERCEIROS, QUE NÃO SÃO AFILIADOS À ENTREPAY. A ENTREPAY NÃO SERÁ RESPONSÁVEL POR QUALQUER INFORMAÇÃO, MATERIAL, PRODUTO OU SERVIÇO DISPONIBILIZADO OU OFERECIDO EM QUALQUER SITE DE TERCEIROS. A ENTREPAY NÃO ENDOSSA NEM RECOMENDA QUALQUER INFORMAÇÃO, PRODUTOS OU SERVIÇOS OFERECIDOS EM QUALQUER SITE DE TERCEIROS, NEM A ENTREPAY É RESPONSÁVEL POR QUALQUER FALHA DE PRODUTOS OU SERVIÇOS OFERECIDOS OU ANUNCIADOS EM QUALQUER LOCAL DE TERCEIROS. A ENTREPAY NÃO É RESPONSÁVEL PELO CONTEÚDO DE QUALQUER SITE DE TERCEIROS. TERCEIROS PODEM TER UMA POLÍTICA DE PRIVACIDADE DIFERENTE DA PRATICADA PELA ENTREPAY, PODENDO FORNECER PADRÕES DE SEGURANÇA INFERIORES AOS PRATICADOS PELA ENTREPAY.</p>
                      <p><strong>NENHUMA GARANTIA</strong></p>
                      <p>AS INFORMAÇÕES E MATERIAIS CONTIDOS NESTE SITE, INCLUINDO TEXTO, GRÁFICOS, LINKS OU OUTROS ITENS, SÃO FORNECIDOSDA MANEIRA COMO SE ENCONTRAM, E CONFORME DISPONIBILIDADE. A ENTREPAY NÃO GARANTE A PRECISÃO, ADEQUAÇÃO OU INTEGRIDADE DE INFORMAÇÕES E MATERIAL, E EXPRESSAMENTE SE ISENTA DA RESPONSABILIDADE POR ERROS OU OMISSÕES NAS INFORMAÇÕES E MATERIAL. NENHUMA GARANTIA DE QUALQUER TIPO, IMPLÍCITA, EXPRESSA OU LEGAL, INCLUINDO, SEM LIMITAÇÃO, AS GARANTIAS DE NÃO VIOLAÇÃO DE DIREITOS DE TERCEIROS, TÍTULO, COMERCIALIZAÇÃO, ADEQUAÇÃO A UM DETERMINADO FIM E ISENÇÃO DE VÍRUS DE COMPUTADOR, É DADA EM CONJUNTO COM AS INFORMAÇÕES E MATERIAL DISPONÍVEIS NESTE SITE. A ENTREPAY NÃO TERÁ RESPONSABILIDADE POR QUALQUER ERRO OU OMISSÕES NO CONTEÚDO DESTE SITE. VOCÊ NÃO PODE AGIR OU CONFIAR EM INFORMAÇÃO OU MATERIAL DESTE SITE, ESPECIALMENTE NO QUE DIGA RESPEITO ÀS DECISÕES DE INVESTIMENTO. VOCÊ DEVE VERIFICAR A VERACIDADE DE TODAS AS INFORMAÇÕES E MATERIAIS ANTES DE AGIR OU NELES CONFIAR, OU TOMAR DECISÕES DE INVESTIMENTO NESSA OCASIÃO.</p>
                      <p><strong>LIMITAÇÃO DE RESPONSABILIDADE</strong></p>
                      <p>EM HIPÓTESE ALGUMA A ENTREPAY SERÁ RESPONSÁVEL POR QUAISQUER DANOS, INCLUINDO, SEM LIMITAÇÃO, DIRETA OU INDIRETAMENTE, ESPECIAL, ACIDENTAL OU CONSEQÜENCIAL,, POR PERDAS OU DESPESAS ORIUNDAS DA CONEXÃO COM ESTE SITE OU QUALQUER SITE VINCULADO, OU USO SEU USO OU INCAPACIDADE DE USAR QUALQUER PARTE, OU EM CONEXÃO COM QUALQUER FALHA OU DESEMPENHO, ERRO, OMISSÃO, INTERRUPÇÃO, DEFEITO, ATRASO NA OPERAÇÃO OU TRANSMISSÃO, VÍRUS DE COMPUTADOR OU FALHA DE LINHA OU DE SISTEMA, MESMO QUE A ENTREPAY, OU SEUS REPRESENTANTES, SEJAM INFORMADOS SOBRE A POSSIBILIDADE DE TAIS DANOS, PERDAS OU DESPESAS.</p>
                      <p><strong>APRESENTAÇÕES</strong></p>
                      <p>Todas as informações enviadas a EntrePay através deste site serão consideradas e permanecerão propriedade da EntrePay, estando a EntrePay livre para usá-las para qualquer propósito ou no desenvolvimento de ideia, conceito, know-how ou técnica extraída da informação que um visitante deste site fornecer à EntrePay através deste site. A EntrePay não estará sujeita a quaisquer obrigações de confidencialidade em relação à informação enviada, exceto quando acordado pela EntrePay, tendo relação direta com cliente ou de outra forma especificamente acordada ou exigida por lei. Nada aqui contido deve ser interpretado como limitando ou reduzindo as responsabilidades e obrigações da EntrePay aos clientes, de acordo com a Declaração de Privacidade da EntrePay.</p>
                      <p><strong>DISPONIBILIDADE</strong></p>
                      <p>Este site não se destina à distribuição ou uso por qualquer pessoa ou entidade em qualquer jurisdição ou país onde tal distribuição ou uso seria contrário à legislação ou regulamentação local.</p>
                      <p><strong>TERMOS ADICIONAIS</strong></p>
                      <p>Algumas seções ou páginas deste site podem conter termos e condições em separado, que contêm disposições adicionais a estes termos e condições. Em caso de conflito, os termos e condições adicionais prevalecerão para estas seções ou páginas.</p>
                      <p><strong>ALTERAÇÕES AOS TERMOS E CONDIÇÕES</strong></p>
                      <p>A EntrePay reserva-se o direito de revisar estes Termos e Condições a qualquer momento, sem aviso prévio. Considera-se, indistintamente, que os usuários do site têm conhecimento sobre estas disposições e estão vinculados a quaisquer alterações a estes Termos e Condições. Você concorda em revisar estes Termos e Condições regularmente e seu contínuo acesso ou uso deste site significa que você aceita as alterações.</p>
                      <p><strong>LEI VIGENTE</strong></p>
                      <p>O uso deste site será regido por todas as leis aplicáveis da República Federativa do Brasil. Os usuários deste site consentem com a jurisdição exclusiva e foro da Comarca de São Paulo, Estado de São Paulo em todas as disputas (a) decorrentes ou relacionadas a este site e / ou estes Termos e Condições, (b) em que este site e / ou estes Termos e condições sejam uma questão de fato, ou (c) em que este site e / ou estes Termos e condições sejam referenciados em um arquivo de papel em tal disputa.</p>
                      <p><strong>GERAL</strong></p>
                      <p>O uso deste site é desautorizado em: (a) qualquer forma que não dê pleno efeito a todas as disposições destes Termos e Condições; e (b) qualquer jurisdição onde o uso deste Site poderá violar quaisquer requisitos legais. Nenhuma relação de joint venture, parceria, emprego ou agência existe como resultado destes Termos e Condições ou pelo simples uso deste site.</p>
                      <p>Estes Termos e Condições constituem a totalidade do acordo entre você e a EntrePay, no que diz respeito a este site, e substituem quaisquer comunicações e propostas prévias ou presentes, sejam eletrônicas, orais, ou de outra forma, entre você e a EntrePay com relação a este site. Nenhuma modificação destes Termos e Condições deverá ser eficaz, a menos que especificamente autorizada por um agente autorizado pela EntrePay. Qualquer suposta renúncia de qualquer violação destes Termos e Condições não será considerada como uma renúncia a qualquer violação futura.</p>
                      <p><strong>CONTATO CONOSCO</strong></p>
                      <p>Se você tem dúvidas sobre estes Termos e Condições, por favor, entre em contato:<span className="Apple-converted-space">&nbsp;</span><a href="mailto:contato@entrepayments.com.br" style={{color: "#78a025", background: "none 0px 0px repeat scroll transparent", textDecoration: "none"}}>contato@entrepayments.com.br</a>.</p>
                    </div>
                  </div>
                </div>
              </Typography>
            </CardContent>
            <div style={{textAlign: "center"}}>
                <Button
                  variant="contained"
                  onClick={props.handleCloseTermosUso}
                  sx={{
                    padding: '16px 16px',
                    lineHeight: 1,
                    marginTop: "5%",
                    backgroundColor: '#0085CE',
                    textTransform: 'none',
                    fontWeight: 500,
                  }}
                >
                    Fechar
                </Button>
            </div>
          </Card>
        </Grid>
      </Grid>
    );
  }

  export default TermosUso;
